<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="setClose"><ion-icon :icon="closeCircleOutline"></ion-icon></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <ion-list>
      <!-- <ion-item>
        <ion-label>지역본부</ion-label>
        <ion-select interface="action-sheet" placeholder="Select One">
          <ion-select-option value="f">Female</ion-select-option>
          <ion-select-option value="m">Male</ion-select-option>
        </ion-select>
      </ion-item> -->
      <ion-item>
        <ion-label>신청종류</ion-label>
        <ion-select interface="action-sheet" placeholder="선택하세요" :value="selectedChgDv" @ionChange="handleChgDv(($event.detail.value))">
          <ion-select-option v-for="(item, index) in chgDvs" :key="index" :value="item.code">{{ item.label }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </ion-content>  
  <ion-footer>
    <ion-button expand="full" size="large" @click="setFilter">검색</ion-button>
  </ion-footer>
</template>

<script>
import { 
  IonHeader, 
  IonToolbar, 
  IonTitle,
  IonButtons, 
  IonButton, 
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption
} from '@ionic/vue'
import { defineComponent, ref, reactive, onMounted, inject } from 'vue'
import { closeCircleOutline } from 'ionicons/icons'

export default defineComponent({
  components: { 
    IonHeader, 
    IonToolbar, 
    IonTitle,
    IonButtons, 
    IonButton, 
    IonContent,
    IonLabel,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption
  },
  props: {
    title: {
      type: String,
      default: '상세'
    },
    modalData: {
      type: Object,
      default: () => {}
    },
    chgDv: {
      type: String,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const eventBus = inject('$eventBus')
    let selectedChgDv = ref(undefined)
    let chgDvs = reactive([])
    chgDvs = [
      {
        code: '',
        label: '전체'
      },
      {
        code: '신규신청',
        label: '신규신청'
      },
      {
        code: '회신청',
        label: '회신청'
      },
      {
        code: '변경신청',
        label: '변경신청'
      }
    ]
    
    onMounted(() => {
      selectedChgDv.value = props.chgDv
    })

    const handleChgDv = (value) => {
      selectedChgDv.value = value
    }

    const setClose = () => {
      emit('close', false)
    }

    const setFilter = () => {
      setClose()
      eventBus.emit('filter', selectedChgDv.value)
    }

    return {
      closeCircleOutline,
      selectedChgDv,
      chgDvs,
      handleChgDv,
      setClose,
      setFilter
    }
  }
})
</script>